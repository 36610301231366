import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import LoggedIn from './pages/loggedIn';
import About from './pages/about';
import Join from './pages/join';
import Price from './pages/pricing';
import FAQ from './pages/loggedIn/FAQ'; // Corrected import path
import Roadmap from './pages/roadMap';
import Header from './pages/Header';
import Footer from './pages/Footer';
import PrivacyPage from './pages/PrivacyPage';
import TermsAndServices from './pages/TermsAndServices';
import XSSProtection from './pages/XSSProtection';
import Inbox from './pages/loggedIn/Inbox'; // Import your Inbox component
import Settings from './pages/Settings'; // Import Settings component
import { auth } from './firebase-config'; // Import auth
import { pageview } from './analytics'; // **Added Import for GA Pageview**
import { Analytics } from "@vercel/analytics/react"


/**
 * **PrivateRoute Component**
 * Restricts access to authenticated users.
 */
function PrivateRoute({ children }) {
  return auth.currentUser ? children : <Navigate to="/login" />;
}

/**
 * **RouteChangeTracker Component**
 * Tracks page views on route changes and sends data to Google Analytics.
 */
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

/**
 * **App Component**
 * Main application component with routing and Google Analytics integration.
 */
function App() {
  const [accessToken, setAccessToken] = useState('');

  return (
    <XSSProtection>
      <Router>
        <div>
          <Header />
          {/* **Added RouteChangeTracker to monitor route changes** */}
          <RouteChangeTracker />
          <Routes>
            <Route path="/logged-in" element={<LoggedIn />} /> 
            <Route path="/login" element={<Login setAccessToken={setAccessToken} />} />
            
            {/* Protected Routes */}
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            <Route
              path="/logged-in/faq"
              element={
                <PrivateRoute>
                  <FAQ />
                </PrivateRoute>
              }
            />
            <Route
              path="/logged-in"
              element={
                <PrivateRoute>
                  <LoggedIn accessToken={accessToken} />
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <Inbox accessToken={accessToken} />
                </PrivateRoute>
              }
            />
            
            {/* Public Routes */}
            {/* <Route path="/register" element={<Register />} /> */}
            {/* <Route path="/about" element={<About />} />
            <Route path="/join" element={<Register />} />
            <Route path="/pricing" element={<Price />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/TermsAndServices" element={<TermsAndServices />} />
            <Route path="/roadmap" element={<Roadmap />} /> */}
          </Routes>
          {/* <Footer /> */}
        </div>
      </Router>
      <Analytics />
    </XSSProtection>
  );
}

export default App;
